<template>
    <div class="setting">
        <el-button type="text" size="medium" @click.stop="openDialog">去设置</el-button>
        <!-- 编辑弹框 -->
        <el-dialog :title="editTitle" :visible.sync="editShow" center :close-on-click-modal="false" width="50%">
            <el-form :model="params" :rules="rules" ref="form" label-width="100px">
                <el-form-item label="卡券权益" prop="cardSwitch">
                    <el-switch v-model="form.cardSwitch"></el-switch>
                </el-form-item>
                <el-form-item label="赠送频率" prop="giveFrequency">
                    <el-radio-group v-model="form.giveFrequency" style="width: 200%" @input="changeGiveFrequency">
                        <el-radio :label="1">达标即送</el-radio>
                        <el-radio :label="2">每月固定日期</el-radio>
                        <el-radio :label="3">每周固定日</el-radio>
                        <el-radio :label="4">每年生日</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="10" style="margin-right: 20px">
                        <el-form-item label="领取开始日" prop="giveStart">
                            <el-select v-model="form.giveStart" class="mr20" placeholder="请选择会员等级">
                                <el-option v-for="item in giveStartEnumList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="领取截止日" prop="giveEnd">
                            <el-select v-model="form.giveEnd" class="mr20" placeholder="请选择会员等级">
                                <el-option v-for="item in giveEndEnumList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="赠送卡券" required prop="levelEquityCardVoList">
                    <div style="display: flex; align-items: center; width: 600px">
                        <el-button type="primary" @click="addCard" style="margin-bottom: 20px">添加卡券</el-button>
                        <p style="color: red; margin-left: 20px">
                            *券池数量不填表示券池总数无限制
                        </p>
                    </div>
                    <div class="box" style="margin-bottom: 0; width: 600px">
                        <el-row :gutter="20" v-for="(item, index) in form.levelEquityCardVoList" :key="index">
                            <el-col :span="10">
                                <el-form-item style="margin-bottom: 20px">
                                    <el-select v-model="item.cardId" filterable remote reserve-keyword
                                        :disabled="item.poolId != null" placeholder="请选择平台卡券"
                                        :remote-method="remoteMethod" :loading="loading"
                                        :popper-class="'custom-select-dropdown'" @focus="
            cardParams.size = 5;
        fetchOptions();
        ">
                                        <div style="min-height: 180px">
                                            <el-option v-for="item in cardOption" :key="item.cardId"
                                                :label="item.cardName" :value="item.cardId">
                                            </el-option>
                                        </div>
                                        <div class="custom-pagination">
                                            <el-pagination background layout="prev, pager, next"
                                                :page-size="cardParams.size" :total="total"
                                                @current-change="handlePageChange" :current-page.sync="currentPage">
                                            </el-pagination>
                                        </div>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input v-model="item.cardPoolNum" type="number" min="1"
                                        :disabled="item.poolId != null" placeholder="请输入券池数量" @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
                e.key
            )
                ? false
                : e.returnValue)
            " @input="input4($event, index)">
                                        <template slot="append">张</template></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="4">
                                <i class="el-icon-delete" @click="deleteCell(index)"></i>
                            </el-col>
                        </el-row>
                    </div>
                </el-form-item>
                <el-form-item label="价格权益" prop="discountSwitch">
                    <el-switch v-model="form.discountSwitch"></el-switch>
                </el-form-item>
                <!-- <el-form-item label="包厢预约">
                    <el-input v-model="form.roomRate" type="number" step="0.1" min="0" max="10" @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-'].includes(e.key)
                ? false
                : e.returnValue)
            " @input="input1" @focus="handleFocus">
                        <template slot="append">折</template>
                    </el-input>
                </el-form-item> -->
                <div style="display: flex; justify-content: space-between;flex-wrap: wrap;">
                    <el-form-item :label="item.templateName" v-for="(item, index) in form.templateRateVoList" :key="index">
                    <el-input v-model="item.rate" type="number" step="0.1" min="0" max="10" @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-'].includes(e.key)
                ? false
                : e.returnValue)
            " @input="input1($event, index)" @focus="handleFocus">
                        <template slot="append">折</template>
                    </el-input>
                </el-form-item>
                </div>
                <div  style="display: flex; justify-content: space-between;flex-wrap: wrap;">
                    <el-form-item label="售卖柜商品">
                    <el-input v-model="form.vendingRate" type="number" step="0.1" min="0" max="10" @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-'].includes(e.key)
                ? false
                : e.returnValue)
            " @input="input2">
                        <template slot="append">折</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="派送点单商品">
                    <el-input v-model="form.dispatchRate" type="number" step="0.1" min="0" max="10" @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-'].includes(e.key)
                ? false
                : e.returnValue)
            " @input="input3">
                        <template slot="append">折</template>
                    </el-input>
                </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getLevelEquityDetail,
    cardEquityEnumVo,
    updateLevelEquity,
} from "@/api/user/level.js";
import { getTableList } from "@/api/card/card";
export default {
    props: {
        equityId: {
            type: [String, Number],
            required: true,
        },
        levelName:{
            type: String,
            default:'',
        },
       
    },
    data() {
        return {
            // 编辑弹框
            infoShow: false, //弹框开关
            form: {
                giveFrequency: 1,
                levelEquityCardVoList: [
                    // { cardId: '', cardPoolNum: '', equityId: '', poolId: null, spId: '', stock: '' }
                ],
            }, //权益详情
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            giveStartEnumList: [], //领取开始日配置
            giveEndEnumList: [], //领取截止日配置
            // from表单
            params: {
                goodsShelvesName: "",
                goodsShelvesNumber: "",
                goodsShelvesCapacity: "",
            },
            rules: {},
            selectedValue: "",
            cardOption: [],
            cardParams: {
                cardType: "",
                page: 1,
                size: 5,
                kw: "",
            },
            loading: false,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            query: "",
        };
    },
    created() { },
    methods: {
        handleFocus() {
            // this.$nextTick(() => {
            //     this.$refs.input.setAttribute("lang", "en");
            // });
        },
        // 折扣输入框校验
        input1(value,index) {
            console.log(value,index)
            if (value.length > 3) {
                this.form.templateRateVoList[index].rate = value.slice(0, 3);
            }

            if (value > 10) {
                this.form.templateRateVoList[index].rate = 10;
            }
        },
        input2(value) {
            if (value.length > 3) {
                this.form.vendingRate = value.slice(0, 3);
            }
            if (value > 10) {
                this.form.vendingRate = 10;
            }
        },
        input3(value) {
            if (value.length > 3) {
                this.form.dispatchRate = value.slice(0, 3);
            }
            if (value > 10) {
                this.form.dispatchRate = 10;
            }
        },
        // 卡券数量校验
        input4(value, index) {
            if (value.length > 6) {
                this.form.levelEquityCardVoList[index].cardPoolNum = value.slice(0, 6);
            }
        },
        reset() {
            this.form = {
                giveFrequency: 1,
                levelEquityCardVoList: [
                    // { cardId: '', cardPoolNum: '', equityId: this.equityId, poolId: null, spId: '', stock: '' }
                ],
            };
        },

        // 弹窗打开初始化 等待 getOption 和 fetchOptions 完成后再执行 getLevelEquityDetail
        async openDialog() {
            await this.getOption();
            this.cardParams.size = 0;
            await this.fetchOptions();
            this.editShow = true;
            this.getLevelEquityDetail();
        },
        // 根据赠送频率更新领取开始日和领取截止日配置项
        changeGiveFrequency(e) {
            this.form.giveFrequency = e;
            this.getOption(true);
        },
        // 获取领取开始日和领取截止日配置项接口
        getOption(isReset) {
            return new Promise((resolve, reject) => {
                cardEquityEnumVo({ giveFrequency: this.form.giveFrequency })
                    .then((res) => {
                        if (res.isSuccess == "yes") {
                            this.giveStartEnumList = res.data.giveStartEnumList;
                            this.giveEndEnumList = res.data.giveEndEnumList;
                            // 只有切换频率的时候才会重置选择第一项
                            if (isReset == true) {
                                this.form.giveStart = res.data.giveStartEnumList[0].value;
                                this.form.giveEnd = res.data.giveEndEnumList[0].value;
                            }
                            resolve(); // 操作完成时调用 resolve
                        } else {
                            reject(new Error("Failed to fetch options")); // 如果有错误则调用 reject
                        }
                    })
                    .catch((error) => {
                        reject(error); // 捕捉错误并调用 reject
                    });
            });
        },
        // 【请求】卡券下拉选择配置
        fetchOptions() {
            return new Promise((resolve, reject) => {
                getTableList(this.cardParams)
                    .then((res) => {
                        if (res.isSuccess == "yes") {
                            this.loading = false;
                            this.cardOption = res.data.list;
                            this.total = res.data.total;
                            resolve(); // 操作完成时调用 resolve
                        } else {
                            reject(new Error("Failed to fetch table list")); // 如果有错误则调用 reject
                        }
                    })
                    .catch((error) => {
                        reject(error); // 捕捉错误并调用 reject
                    });
            });
        },
        // 【请求】权益详情
        getLevelEquityDetail() {
            getLevelEquityDetail({ equityId: this.equityId }).then((res) => {
                if (res.isSuccess == "yes") {
                    if (res.data) {
                        this.form = res.data;
                        if (!res.data.levelEquityCardVoList) {
                            this.form.levelEquityCardVoList = [
                                {
                                    cardId: "",
                                    cardPoolNum: "",
                                    equityId: this.equityId,
                                    poolId: null,
                                    spId: "",
                                    stock: "",
                                },
                            ];
                        }

                        if (!res.data.giveFrequency) {
                            this.form.giveFrequency = 1;
                        }
                        this.getOption();
                        if (!res.data.giveStart) {
                            this.form.giveStart = this.giveStartEnumList[0].value;
                        }
                        if (!res.data.giveEnd) {
                            this.form.giveEnd = this.giveEndEnumList[0].value;
                        }
                        console.log("this.form", this.form);
                        this.editTitle =this.levelName+'-'+res.data.equityName;
                    }
                }
            });
        },
        // 添加卡券
        addCard() {
            const obj = {
                cardId: "",
                cardPoolNum: "",
                equityId: this.equityId,
                poolId: null,
            };
            this.form.levelEquityCardVoList.push(obj);
        },
        // 【监听】删除
        deleteCell(index) {
            if (this.form.cardSwitch && this.form.levelEquityCardVoList.length == 1) {
                this.$message({
                    message: "卡券权益开启时至少选择一张卡券",
                    type: "warning",
                });
                return;
            }
            this.form.levelEquityCardVoList.splice(index, 1);
        },
        // 卡券搜索
        remoteMethod(query) {
            this.cardParams.kw = query;
            if (query !== "") {
                this.loading = true;
                this.fetchOptions();
            } else {
                this.cardOption = [];
            }
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.cardParams.page = page;
            this.fetchOptions();
        },

        // 【监听】表单提交
        submit() {
            if(!this.form.cardSwitch&&!this.form.discountSwitch){
                this.$message({
                    message: "至少开启一项权益",
                    type: "error",
                });
                return;
            }
            if (this.form.cardSwitch && this.form.levelEquityCardVoList.length == 0) {
                this.$message({
                    message: "卡券权益开启时至少添加一张卡券",
                    type: "error",
                });
                return;
            }
            if (this.form.levelEquityCardVoList.length > 0) {
                let i = this.form.levelEquityCardVoList.findIndex(
                    (item) => item.cardId == ""
                );
                if (i != -1) {
                    this.$message({
                        message: "请检查所选择卡券是否为空",
                        type: "error",
                    });
                    return;
                }
            }
            this.form.equityId = this.equityId;
            if (!this.form.cardSwitch) {
                this.form.levelEquityCardVoList = [];
            }
            this.form.equityId = this.equityId;
            if (!this.form.cardSwitch) {
                this.form.levelEquityCardVoList = [];
            }

            updateLevelEquity(this.form).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.editShow = false;
                    this.reset();
                    this.$emit("refresh"); // 【请求】表格数据
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.setting {
    .custom-select-dropdown {
        position: relative;
    }

    .custom-pagination {
        padding: 10px;
        text-align: center;
    }
}
</style>
